import React from "react"
import Layout from "../components/layout"

export default function About() {
  return (
    <Layout>
      <h1 className="text-3xl">Oops, nothing here.</h1>
    </Layout>
  )
}
